import React from 'react';
import {
  Platform,
  StyleSheet,
  Text,
  TextInput as NativeTextInput,
  TouchableOpacity,
  View,
} from 'react-native';

import {textStyle} from './Layout';

export const TextInput = React.forwardRef((props, ref) => {
  let keyboardType = props.keyboardType;

  if (Platform.OS === 'web' && props.keyboardType === 'numeric') {
    keyboardType = null;
  }

  return (
    <NativeTextInput
      ref={ref}
      autoCompleteType="off"
      autoCorrect={false}
      placeholderTextColor="#aaa"
      maxFontSizeMultiplier={1}
      {...props}
      keyboardType={keyboardType}
      style={[styles.textInput, props.style]}
    />
  );
});

export const Button = (props) => (
  <TouchableOpacity
    testID={props.testID}
    style={props.blockStyle}
    onPress={props.onPress}
    onLayout={props.onLayout}>
    <View
      style={StyleSheet.flatten([
        styles.button,
        props.primary ? styles.primaryButton : {},
        props.style,
      ])}>
      <Text style={[styles.buttonText, props.textStyle]}>{props.children}</Text>
    </View>
  </TouchableOpacity>
);

const styles = StyleSheet.create({
  textInput: {
    ...textStyle,
    fontSize: textStyle.fontSize * 1.18,
    margin: 10,
    backgroundColor: 'rgba(39,51,8,.7)',
    borderRadius: 10,
    color: '#fff',
    padding: 5,
    paddingLeft: 10,
    width: 'auto',
  },
  button: {
    padding: 10,
    backgroundColor: 'rgba(39,51,8,.8)',
    color: '#fff',
  },
  primaryButton: {
    backgroundColor: 'rgba(136,36,32,.8)',
  },
  buttonText: {
    ...textStyle,
    fontSize: textStyle.fontSize * 0.88,
    color: '#fff',
  },
});
