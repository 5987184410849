import React, {Suspense} from 'react';
import {View} from 'react-native';
import {styled} from 'linaria/react';

import 'overlayscrollbars/overlayscrollbars.css';

const ScrollComponent = styled(
  React.lazy(async () => ({
    default: (await import('overlayscrollbars-react'))
      .OverlayScrollbarsComponent,
  })),
)`
  flex: 1;
`;

export const ScrollView = React.forwardRef(function ScrollView(
  {children, ...props},
  ref,
) {
  return (
    <Suspense
      fallback={
        <div>
          <View {...props}>{children}</View>
        </div>
      }>
      <ScrollComponent ref={ref} options={{overflowBehavior: {x: 'hidden'}}}>
        <View {...props}>{children}</View>
      </ScrollComponent>
    </Suspense>
  );
});
