/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */

import React, {Suspense, useEffect, useLayoutEffect, useState} from 'react';
import {Platform, NativeModules} from 'react-native';
import RNBootSplash from 'react-native-bootsplash';
import {NavigationContainer} from '@react-navigation/native';
import {createStackNavigator} from '@react-navigation/stack';
import {GestureHandlerRootView} from 'react-native-gesture-handler';

import {HeaderBar, Background} from './components/Layout';
import {getSetting} from './lib/storage';

export let isFirstRun = false;
const Stack = createStackNavigator();
const linking = {
  prefixes: ['mytest://'],
  config: {
    screens: {
      ScanResult: 'result/:barcode',
      SubmitResult: {
        path: 'submit/:barcode/:info',
        parse: {
          info: JSON.parse,
        },
        stringify: {
          info: JSON.stringify,
        },
      },
    },
  },
};

function makePageWrapper(comp) {
  const LazyComp = React.lazy(comp);

  return function PageWrapper(props) {
    return (
      <Suspense fallback={<Background loading={true} />}>
        <LazyComp {...props} />
      </Suspense>
    );
  };
}

const Camera = makePageWrapper(() => import('./pages/Camera'));
const ScanResult = makePageWrapper(() => import('./pages/ScanResult'));
const SubmitResult = makePageWrapper(() => import('./pages/SubmitResult'));
const Statistics = makePageWrapper(() => import('./pages/Statistics'));
const ScanHistory = makePageWrapper(() => import('./pages/ScanHistory'));
const AboutUs = makePageWrapper(() => import('./pages/AboutUs'));
const ThankYou = makePageWrapper(() => import('./pages/ThankYou'));
const Privacy = makePageWrapper(() => import('./pages/Privacy'));
const Impressum = makePageWrapper(() => import('./pages/Impressum'));
const IntroSlider = makePageWrapper(() => import('./pages/IntroSlider'));

const RootStack = props => (
  <Stack.Navigator
    initialRouteName={props.isFirstRun ? 'IntroSlider' : 'Home'}
    screenOptions={{
      header: hProps => <HeaderBar {...hProps} />,
      animationEnabled: false,
    }}>
    <Stack.Screen name="Home" component={require('./pages/Scan').default} />
    <Stack.Screen name="Camera" component={Camera} />
    <Stack.Screen name="ScanResult" component={ScanResult} />
    <Stack.Screen name="SubmitResult" component={SubmitResult} />
    <Stack.Screen name="Statistics" component={Statistics} />
    <Stack.Screen name="ScanHistory" component={ScanHistory} />
    <Stack.Screen name="AboutUs" component={AboutUs} />
    <Stack.Screen name="ThankYou" component={ThankYou} />
    <Stack.Screen name="Privacy" component={Privacy} />
    <Stack.Screen name="Impressum" component={Impressum} />
    <Stack.Screen name="IntroSlider" component={IntroSlider} />
    <Stack.Screen name="Menu" component={require('./screens/Menu').default} />
  </Stack.Navigator>
);

let bridgeNotified = false;
const firstRunPromise = (async () => {
  const value = await getSetting('sliderSeen', 'n');
  isFirstRun = value === 'n';
})();

async function hideSplash(setVisible) {
  await firstRunPromise;
  setVisible(true);

  if (Platform.OS !== 'web') {
    RNBootSplash.hide({duration: 300});
  }
}

export default () => {
  const [isVisible, setVisible] = useState(false);

  useEffect(() => {
    if (__DEV__) {
      if (Platform.OS !== 'web') {
        RNBootSplash.hide({duration: 0});
      }
      setVisible(true);

      if (global.TEST_BRIDGE) {
        NativeModules.TestBridge.setEnvInfo({
          isWide: require('react-native').Dimensions.get('window').width > 950,
        });
      }
    } else {
      if (Platform.OS === 'ios') {
        setTimeout(() => {
          hideSplash(setVisible);
        }, 3000);
      } else {
        hideSplash(setVisible);
      }
    }
  }, []);

  if (global.TEST_BRIDGE) {
    useLayoutEffect(() => {
      if (isVisible && !bridgeNotified) {
        bridgeNotified = true;
        setTimeout(() => NativeModules.TestBridge.finishWork(), 1300);
      }
    }, [isVisible]);
  }

  return isVisible ? (
    <GestureHandlerRootView style={{flex: 1}}>
      <NavigationContainer linking={Platform.OS === 'web' ? null : linking}>
        <RootStack isFirstRun={isFirstRun} />
      </NavigationContainer>
    </GestureHandlerRootView>
  ) : null;
};
