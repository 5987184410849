import React, {useState} from 'react';
import ReactDOM from 'react-dom';
import Modal from 'react-overlays/Modal';
import {styled} from 'linaria/react';

const Backdrop = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.25);
`;

const StyledModal = styled(Modal)`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 500px;
  margin-left: -250px;
  border-radius: 10px;
  background: #fff;
  padding: 10px;
`;

const Title = styled.h1`
  font-family: 'Lato-Regular';
  text-align: center;
`;

const Message = styled.p`
  font-family: 'Lato-Regular';
`;

const ModalButton = styled.a`
  font-family: 'Lato-Bold';
  display: block;
  padding: 5px;
  text-decoration: none;
  text-align: center;
  color: rgb(30, 30, 200);
`;

let addAlert = null;
let idCounter = 0;

function AlertContainer() {
  const [alerts, setAlerts] = useState([]);
  const remove = (id) => setAlerts((as) => as.filter((a) => a.id !== id));

  addAlert = (data) => {
    setAlerts((a) => [...a, {...data, id: idCounter++}]);
  };

  return (
    <>
      {alerts.map((alert) => (
        <StyledModal
          key={alert.id}
          show={true}
          onHide={() => remove(alert.id)}
          renderBackdrop={(props) => <Backdrop {...props} />}
          aria-labelledby="modal-label">
          <div>
            <Title id="modal-label">{alert.title}</Title>
            <Message>{alert.message}</Message>
            <ModalButton href="#" onClick={() => remove(alert.id)}>
              Schließen
            </ModalButton>
          </div>
        </StyledModal>
      ))}
    </>
  );
}

export const Alert = {
  alert(title, message) {
    addAlert({title, message, toast: false});
  },
};

export function toastOrAlert(title, message) {
  addAlert({title, message, toast: true});
}

const overlayContainer = document.createElement('div');
document.body.appendChild(overlayContainer);
ReactDOM.render(<AlertContainer />, overlayContainer);
