/* globals localStorage */
import { nanoid } from "nanoid";

export function setSetting(key, value) {
  if (window.localStorage) {
    localStorage.setItem(key, JSON.stringify(value));
  }
}

export function getSetting(key, defaultValue) {
  if (window.localStorage) {
    try {
      const value = JSON.parse(localStorage.getItem(key));
      return value === null ? defaultValue : value;
    } catch (e) {
      console.error(e);
      return defaultValue;
    }
  } else {
    return defaultValue;
  }
}

export async function getId() {
  let id = getSetting('userID', null);
  if (id === null) {
    id = nanoid(12);
    setSetting('userID', id);
  }

  return id;
}

export function appendScan(barcode, data) {
  if (window.localStorage) {
    try {
      let scans = JSON.parse(localStorage.getItem('scans'));
      if (scans === null) {
        scans = [];
      }

      scans.push({
        ...data,
        barcode: data.barcode || barcode,
      });
      localStorage.setItem('scans', JSON.stringify(scans));
    } catch (e) {
      console.error(e);
    }
  }
}

export function getScans() {
  if (window.localStorage) {
    try {
      const scans = JSON.parse(localStorage.getItem('scans'));
      return scans === null ? [] : scans;
    } catch (e) {
      console.error(e);
      return [];
    }
  } else {
    return [];
  }
}

export async function countScans(barcode) {
  const scans = await getScans();
  let count = 0;
  for (const row of scans) {
    if (row.barcode === barcode) {
      count++;
    }
  }

  return count;
}

export function deleteScan(id) {
  if (window.localStorage) {
    try {
      let scans = JSON.parse(localStorage.getItem('scans'));
      if (scans !== null) {
        scans = scans.filter((value) => value.id !== id);
        localStorage.setItem('scans', JSON.stringify(scans));
      }
    } catch (e) {
      console.error(e);
    }
  }
}
