import React from 'react';
import {Platform, StyleSheet, Text, View, TouchableOpacity} from 'react-native';
import {format} from 'fecha';

import {useFetch} from '../lib/fetch';

import {
  Background,
  Footer,
  Header,
  LoadingIndicator,
  Separator,
  WidthLimit,
  textStyle,
  boldStyle,
} from '../components/Layout';
import Subtitle from '../components/Subtitle';

import PostcardIcon from '../res/postcard.svg';
import MailIcon from '../res/mail.svg';
import ScannerIcon from '../res/scanner.svg';
import FactoryIcon from '../res/factory.svg';

function humanizeNumber(value) {
  let result = '' + (value || '');
  let l = result.length;
  for (let i = 3; i < l; i += 3) {
    result = result.substring(0, l - i) + '.' + result.substring(l - i);
  }

  return result;
}

export function StatisticsWidget() {
  const data = useFetch('https://api.replacepalmoil.de/stats/');
  const mails = data.result?.mails_details || [];
  mails.sort((a, b) => b.sentTime - a.sentTime);

  return (
    <>
      <Subtitle style={styles.title}>Zahlen, Daten, Fakten:</Subtitle>

      {data.error ? (
        <View style={styles.errorBox}>
          <Text style={styles.errorText}>
            Die Informationen konnten leider nicht geladen werden. Dies kann
            passieren, wenn momentan keine Internetverbindung besteht oder die
            Website von ReplacePalmOil nicht erreichbar ist.
          </Text>
          <TouchableOpacity onPress={() => data.refetch()}>
            <Text style={[styles.errorText, styles.errorButton]}>
              Erneut versuchen
            </Text>
          </TouchableOpacity>
        </View>
      ) : null}

      <LoadingIndicator active={data.loading} />

      <View style={styles.content}>
        <View style={[styles.line, styles.statContainer]}>
          <View style={[styles.box, styles.statBox]}>
            <PostcardIcon width="100%" height={40} />
            <Text style={[styles.text, styles.label]}>Einsendungen:</Text>
            <Text style={[styles.text, styles.value]}>
              {humanizeNumber(data.result?.entry_count)}
            </Text>
          </View>
          <View style={[styles.box, styles.statBox]}>
            <MailIcon width="100%" height={40} />
            <Text style={[styles.text, styles.label]}>Mails versendet:</Text>
            <Text style={[styles.text, styles.value]}>
              {humanizeNumber(data.result?.mails_sent)}
            </Text>
          </View>
        </View>

        <View style={[styles.line, styles.statContainer]}>
          <View style={[styles.box, styles.statBox]}>
            <ScannerIcon width="100%" height={40} />
            <Text style={[styles.text, styles.label]}>Produkte gescannt:</Text>
            <Text style={[styles.text, styles.value]}>
              {humanizeNumber(data.result?.product_count)}
            </Text>
          </View>
          <View style={[styles.box, styles.statBox]}>
            <FactoryIcon width="100%" height={40} />
            <Text style={[styles.text, styles.label]}>
              Hersteller / Anbieter verzeichnet:
            </Text>
            <Text style={[styles.text, styles.value]}>
              {humanizeNumber(data.result?.vendor_count)}
            </Text>
          </View>
        </View>

        <Separator style={styles.sepMargin} />
        <Subtitle style={styles.title}>
          An diese Hersteller / Anbieter wird viel Feedback gesendet:
        </Subtitle>

        <View style={[styles.box, styles.vendorList]}>
          {(data.result?.top_vendors.slice(0, 10) || []).map((vendor, i) => (
            <View key={i} style={styles.line}>
              <Text
                style={[
                  styles.text,
                  styles['vendor' + Math.round((i + 1) / 2)],
                ]}>
                {vendor.data}
              </Text>
            </View>
          ))}
        </View>

        <Separator style={styles.sepMargin} />
        <Subtitle style={styles.title}>
          Auswahl kürzlich eingereichter Produkte:
        </Subtitle>

        {(data.result?.latest_products || []).slice(0, 5).map((product, i) => (
          <View key={i} style={styles.vendorContainer} padding={0}>
            <View style={styles.vendorBox}>
              <View style={styles.vendorDateContainer}>
                <View style={styles.vendorDate}>
                  <Text
                    style={[styles.text, styles.vendorDateText]}
                    maxFontSizeMultiplier={1.2}
                    numberOfLines={1}>
                    {format(new Date(product.createdAt), 'DD.')}
                  </Text>
                  <Text
                    style={[styles.text, styles.vendorDateText]}
                    maxFontSizeMultiplier={1.2}
                    numberOfLines={1}>
                    {format(new Date(product.createdAt), 'MM.')}
                  </Text>
                  <Text
                    style={[styles.text, styles.vendorDateText]}
                    maxFontSizeMultiplier={1.2}
                    numberOfLines={1}>
                    {format(new Date(product.createdAt), 'YYYY')}
                  </Text>
                </View>
              </View>
              <View style={styles.vendorDetails}>
                <View style={styles.line}>
                  <Text
                    style={[styles.text, styles.vendorName]}
                    maxFontSizeMultiplier={1.2}>
                    {product.detailname
                      .replace(/[\r\n]+$/, '')
                      .replace(/([0-9]+) g/, '$1g')}
                  </Text>
                </View>
                <View style={styles.line}>
                  <Text>
                    <Text
                      style={[styles.text, styles.vendorLabel]}
                      maxFontSizeMultiplier={1.2}>
                      Hersteller / Anbieter:{' '}
                    </Text>
                    <Text
                      style={[styles.text, styles.vendorValue]}
                      maxFontSizeMultiplier={1.2}>
                      {product.vendor.replace(/[\r\n]+$/, '')}
                    </Text>
                  </Text>
                </View>
                <View style={styles.line}>
                  <Text
                    style={[styles.text, styles.vendorLabel]}
                    maxFontSizeMultiplier={1.2}>
                    Barcode:{' '}
                  </Text>
                  <Text
                    style={[styles.text, styles.vendorValue]}
                    maxFontSizeMultiplier={1.2}>
                    {product.barcode.replace(/[\s]+/, '')}
                  </Text>
                </View>
              </View>
            </View>
          </View>
        ))}

        <Separator />
        <Subtitle style={styles.title}>
          Kürzlich versandte Mails:
        </Subtitle>

        {mails.slice(0, 5).map((mail, i) => (
          <View key={i} style={styles.vendorContainer} padding={0}>
            <View style={styles.vendorBox}>
              <View style={styles.vendorDateContainer}>
                <View style={styles.vendorDate}>
                  <Text
                    style={[styles.text, styles.vendorDateText]}
                    maxFontSizeMultiplier={1.2}
                    numberOfLines={1}>
                    {format(new Date(mail.sentTimeISO), 'DD.')}
                  </Text>
                  <Text
                    style={[styles.text, styles.vendorDateText]}
                    maxFontSizeMultiplier={1.2}
                    numberOfLines={1}>
                    {format(new Date(mail.sentTimeISO), 'MM.')}
                  </Text>
                  <Text
                    style={[styles.text, styles.vendorDateText]}
                    maxFontSizeMultiplier={1.2}
                    numberOfLines={1}>
                    {format(new Date(mail.sentTimeISO), 'YYYY')}
                  </Text>
                </View>
              </View>
              <View style={styles.vendorDetails}>
                <Text style={[styles.line]}>
                  <MailIcon height={23} viewBox="5 0 24 26" />
                  <Text
                    style={[styles.text, styles.mailSubject]}
                    maxFontSizeMultiplier={1.2}>
                    An: {mail.vendorName}
                  </Text>
                </Text>
                <View style={styles.line}>
                  <Text
                    style={[styles.text, styles.vendorLabel]}
                    maxFontSizeMultiplier={1.2}>
                    {mail.productTitles.length + ''} Produkte:
                  </Text>
                </View>
                <View style={[]}>
                  {mail.productTitles.map((product, k) => (
                    <Text
                      key={k}
                      style={[styles.text]}
                      maxFontSizeMultiplier={1.2}>
                      {product
                        .replace(/[\r\n]/g, '')
                        .replace(/([0-9]+) g/, '$1g')}
                    </Text>
                  ))}
                </View>
              </View>
            </View>
          </View>
        ))}
      </View>
    </>
  );
}

export default () => {
  return (
    <Background>
      <WidthLimit fixed>
        <Header />
        <StatisticsWidget />
      </WidthLimit>
      <Footer />
    </Background>
  );
};

const shadow =
  Platform.OS === 'web' ? {boxShadow: '0 0 5px rgba(0, 0, 00, 0.3)'} : {};

const styles = StyleSheet.create({
  red: {
    borderWidth: 1,
    borderColor: 'red',
  },
  errorBox: {
    backgroundColor: '#aa1010',
    padding: 10,
  },
  errorText: {
    ...textStyle,
    color: '#fff',
  },
  errorButton: {
    ...boldStyle,
    marginTop: 20,
    padding: 10,
    backgroundColor: '#da4040',
    borderRadius: 3,
  },
  title: {
    marginBottom: 10,
  },
  content: {
    paddingTop: Platform.OS === 'web' ? 0 : 5,
    padding: 20,
  },
  box: {
    borderWidth: 1,
    borderColor: '#a62731',
  },
  line: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  noBreak: {
    flexWrap: 'nowrap',
  },
  sepMargin: {
    marginTop: 13,
  },
  statBox: {
    height: 180,
    width: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 10,
    textAlign: 'center',
  },
  statContainer: {
    alignSelf: 'center',
    width: '100%',
    maxWidth: 400,
  },
  text: {
    ...textStyle,
    fontSize: textStyle.fontSize * 0.88,
    color: '#a62731',
  },
  value: {
    fontSize: textStyle.fontSize * 1.65,
  },
  vendorContainer: {
    marginBottom: 10,
  },
  vendorBox: {
    elevation: 2,
    borderWidth: 1,
    borderColor: '#a62731',
    padding: 10,
    flexDirection: 'row',
    backgroundColor: 'rgba(255, 255, 255, 0.4)',
    ...shadow,
  },
  vendorDateContainer: {
    backgroundColor: 'rgba(255, 255, 255, 0.5)',
    justifyContent: 'center',
    alignItems: 'center',
    //width: 130,
  },
  vendorDate: {
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  vendorDateText: {
    fontSize: textStyle.fontSize * 0.59,
  },
  vendorDetails: {
    flex: 4,
    paddingLeft: 10,
    paddingRight: 10,
  },
  vendorName: {
    ...boldStyle,
    fontSize: boldStyle.fontSize * 1.06,
  },
  vendorLabel: {
    ...boldStyle,
    fontSize: textStyle.fontSize * 0.88,
  },
  mailSubject: {
    ...boldStyle,
    fontSize: boldStyle.fontSize * 1.06,
  },
  vendorList: {
    padding: 10,
  },
  vendor1: {
    fontSize: textStyle.fontSize * 1.18,
  },
  vendor2: {
    fontSize: textStyle.fontSize * 1.06,
  },
  vendor3: {
    fontSize: textStyle.fontSize * 0.88,
  },
  vendor4: {
    fontSize: textStyle.fontSize * 0.76,
  },
  vendor5: {
    fontSize: textStyle.fontSize * 0.59,
  },
});
