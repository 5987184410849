import React from 'react';
import {Linking, Platform, TouchableOpacity} from 'react-native';

let openURL = null;
if (Platform.OS === 'web') {
  openURL = (url) => window.open(url, '_blank', '', false);
} else {
  openURL = (url) => Linking.openURL(url);
}

export const Link = (props) => (
  <TouchableOpacity onPress={() => openURL(props.href)} {...props}>
    {props.children}
  </TouchableOpacity>
);
