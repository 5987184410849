import React, {useState, useEffect, useLayoutEffect, useRef} from 'react';
import {
  Platform,
  StyleSheet,
  Text,
  Image,
  TouchableOpacity,
  View,
  useWindowDimensions,
} from 'react-native';
import {
  CopilotProvider,
  walkthroughable,
  CopilotStep,
  useCopilot,
} from '../copilot';
import {PreviousNextView} from 'react-native-keyboard-manager';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import styled from 'styled-native';

import {Alert} from '../lib/alert';
import Barcoder from '../lib/barcoder';
import {setSetting} from '../lib/storage';
import {linkHandler} from '../lib/platform';

import ScanIcon from '../res/scan.svg';
import AppleStoreLogo from '../res/apple_store_logo.svg';
import {
  Background,
  Header,
  Footer,
  textStyle,
  boldStyle,
} from '../components/Layout';
import Subtitle from '../components/Subtitle';
import {Button, TextInput} from '../components/Inputs';
import {Link} from '../components/Link';

import {StatisticsWidget} from './Statistics';

const CopilotView = walkthroughable(View);
const CopilotTextInput = walkthroughable(TextInput);

const MenuPlaceholder = styled(CopilotView)`
  position: absolute;
  top: -60px;
  right: 0;
  width: 50px;
  height: 50px;
  background: #f00;
`;

const ScanButton = styled(CopilotView)`
  margin-top: 10px;
  align-self: center;
  background-color: #a62731;
  border-radius: 150px;
  border: 10px solid #be606c;
  width: 150px;
  height: 150px;
`;

const ScanIconContainer = styled(View)`
  height: 60px;
  margin-top: 25px;
  align-content: center;
`;

const ButtonText = styled(Text)`
  extends: ${boldStyle};
  color: #fff;
  text-align: center;
`;

const googleStoreLogoSize = {
  width: 646 * 0.5,
  height: 250 * 0.5,
};

const appleStoreLogoSize = {
  width: 520 * 0.5,
  height: 250 * 0.5,
};

export const copilotRef = {
  start: null,
  immediate: false,
};

export const scanState = {
  manualEntry: false,
  resetBarcode: () => null,
};

let scrollToTop = false;

const Scan = ({navigation}) => {
  const {start, copilotEvents} = useCopilot();
  const [barcode, setBarcode] = useState('');
  const scrollRef = useRef(null);
  const dim = useWindowDimensions();
  const isWide = dim.width > 950;

  scanState.resetBarcode = () => setBarcode('');

  useEffect(() => {
    copilotRef.start = () => {
      if (Platform.OS === 'web') {
        scrollToTop = true;
      } else {
        scrollRef.current.scrollTo({x: 0, y: 0, animated: false});
      }
      if (!global.__SNAPSHOT__) {
        start();
      }
    };
    copilotEvents.on('stop', () => {
      setSetting('sliderSeen', 'y');
    });

    if (copilotRef.immediate) {
      copilotRef.immediate = false;
      start();
    }
  }, [start, copilotEvents]);

  useLayoutEffect(() => {
    if (scrollToTop) {
      scrollRef.current.osInstance().scroll({x: 0, y: 0}, 0);
      scrollToTop = false;
    }
    // Why can't this rule see that it's used in the if condition ?!!?!?
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollToTop]);

  const sendScan = () => {
    if (!Barcoder.validate(barcode)) {
      Alert.alert(
        'Fehler',
        'Der eingegebene Barcode ist keine gültige Europäische Artikel Nummer (EAN). Entweder liegt ein Tippfehler vor oder die Bearbeitung dieses Produktes ist nicht möglich.',
      );
      return;
    }

    scanState.manualEntry = true;
    navigation.navigate('ScanResult', {barcode});
  };

  const showStatistics = () => {
    navigation.navigate('Statistics');
  };

  return (
    <Background maxFontSizeMultiplier={2} scrollRef={scrollRef}>
      <Header />

      <CopilotStep
        order={3}
        name="menu"
        text="Über diese Schaltfläche kannst du das Menü und andere Seiten wie die Liste der gescannten Produkte öffnen.">
        <MenuPlaceholder />
      </CopilotStep>

      <View style={[styles.row, styles.headerMargin]}>
        <View style={styles.half}>
          <Subtitle
            red={true}
            onHelpPress={() => navigation.navigate('IntroSlider')}>
            Barcode eines palmölhaltigen Lebensmittels scannen:
          </Subtitle>

          <View style={styles.scanContainer}>
            <TouchableOpacity
              testID="scanButton"
              onPress={() => {
                scanState.manualEntry = false;
                navigation.navigate('Camera');
              }}>
              <CopilotStep
                text="Drück den Scan-Button, um den Barcode-Scanner zu öffnen. Halte danach den Barcode des Lebensmittels vor die Kamera."
                order={1}
                name="button">
                <ScanButton>
                  <ScanIconContainer>
                    <ScanIcon width="100%" height="100%" />
                  </ScanIconContainer>
                  <ButtonText>Scannen</ButtonText>
                </ScanButton>
              </CopilotStep>
            </TouchableOpacity>
          </View>
          <View style={styles.content}>
            <Text style={styles.bodytext} maxFontSizeMultiplier={1}>
              ... oder selber die Lebensmittel-Barcode-Nummer eingeben:
            </Text>
            <PreviousNextView>
              <CopilotStep
                text="Alternativ kannst du selber einen Barcode eingeben."
                order={2}
                name="textInput">
                <CopilotTextInput
                  maxFontSizeMultiplier={1.2}
                  testID="barcodeInput"
                  style={[styles.scanInput]}
                  onChangeText={text => setBarcode(text)}
                  onSubmitEditing={() => sendScan()}
                  value={barcode}
                  placeholder="Lebensmittel-Barcode-Nummer"
                  keyboardType="numeric"
                  returnKeyType="send"
                />
              </CopilotStep>
            </PreviousNextView>
            <View style={styles.vbox}>
              <Button
                testID="submitBtn"
                style={styles.vboxContent}
                primary={true}
                onPress={sendScan}>
                Nummer senden
              </Button>
              {isWide ? null : (
                <Button
                  testID="statsBtn"
                  style={styles.vboxContent}
                  onPress={showStatistics}>
                  Statistiken ansehen
                </Button>
              )}
            </View>
            <TouchableOpacity
              style={styles.infoBoxContainer}
              onPress={() =>
                linkHandler(
                  'https://orang-utans-in-not.org/de/projekte/replace-palmoil-app/es-geht-auch-anders',
                )
              }>
              <View style={styles.infoBox}>
                <Text style={styles.infoBoxTitle}>Es geht auch anders!</Text>
                <Text style={styles.infoBoxText}>
                  Auf unserer <Text style={styles.infoBoxLink}>Website</Text>{' '}
                  stellen wir Unternehmen vor, die zeigen, dass es auch ohne
                  Palmöl geht.
                </Text>
                <Text style={styles.infoBoxArrow}>→</Text>
              </View>
            </TouchableOpacity>

            {Platform.OS === 'web' ? (
              <View style={styles.appStoreNotice}>
                <Text style={styles.bodytext}>Auch als App verfügbar:</Text>
                <View>
                  <View
                    style={[styles.textContainer, styles.storeLogoContainer]}>
                    <Link href="https://play.google.com/store/apps/details?id=com.orangutansinnot.replacepalmoil">
                      <Image
                        style={styles.playStoreLogo}
                        source={require('../res/play_logo.png')}
                      />
                    </Link>
                    <Link
                      style={styles.appleStoreContainer}
                      href="https://apps.apple.com/de/app/replace-palmoil/id1478398703">
                      <AppleStoreLogo style={appleStoreLogoSize} />
                    </Link>
                  </View>
                </View>
              </View>
            ) : null}
          </View>
        </View>
        {isWide ? (
          <View style={[styles.half, styles.statsHalf]}>
            <StatisticsWidget />
          </View>
        ) : null}
      </View>

      <Footer />
    </Background>
  );
};

export default props => {
  const insets = useSafeAreaInsets();
  return (
  <CopilotProvider
    labels={{
      previous: 'Zurück',
      next: 'Weiter',
      skip: 'Überspringen',
      finish: 'Beenden',
    }}
    tooltipStyle={{color: '#000'}}
    verticalOffset={Platform.OS === 'android' ? insets.top + 46 : 0}>
    <Scan {...props} />
  </CopilotProvider>
)};

const styles = StyleSheet.create({
  headerMargin: {
    marginTop: 0,
  },
  scanContainer: {
    alignSelf: 'center',
    width: 150,
  },
  bodytext: {
    ...textStyle,
    color: '#a62731',
    textAlign: 'center',
  },
  scanInput: {
    marginTop: 10,
    marginBottom: 20,
    textAlign: 'center',
  },
  content: {
    padding: 10,
  },
  vbox: {
    flexDirection: 'row',
    justifyContent: 'center',
  },
  vboxContent: {
    marginLeft: 5,
    marginRight: 5,
  },
  row: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  half: {
    flex: 1,
    flexDirection: 'column',
    maxWidth: 800,
  },
  statsHalf: {},
  infoBoxContainer: {
    marginTop: Platform.OS === 'web' ? 60 : 20,
    marginLeft: 10,
    marginRight: 10,
  },
  infoBox: {
    borderRadius: 4,
    backgroundColor: 'rgba(39,51,8,.7)',
    padding: 10,
    maxWidth: 500,
    alignSelf: 'center',
  },
  infoBoxTitle: {
    fontSize: 22,
    color: '#fff',
    textAlign: 'center',
    marginBottom: 8,
  },
  infoBoxText: {
    color: '#fff',
    textAlign: 'center',
    fontSize: 16,
  },
  infoBoxLink: {
    color: '#fff',
    textDecorationLine: 'underline',
  },
  infoBoxArrow: {
    color: '#fff',
    fontSize: 35,
    textAlign: 'center',
    paddingTop: 5,
  },
  appStoreNotice: {
    marginTop: 20,
  },
  playStoreLogo: googleStoreLogoSize,
  appleStoreContainer: {
    paddingLeft: 19,
    paddingRight: 19,
  },
  storeLogoContainer: {
    justifyContent: 'center',
    flexDirection: 'row',
  },
});
