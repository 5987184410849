import {AppRegistry} from 'react-native';
import 'core-js/web/immediate';
import ResizeObserver from 'resize-observer-polyfill';
import * as Sentry from '@sentry/browser';

if (!window.ResizeObserver) {
  window.ResizeObserver = ResizeObserver;
}

Sentry.init({
  dsn: 'https://4d804ed6647349ebbe772b121f547272@sentry.gruenprint.de/13',
});

AppRegistry.registerComponent('palmoil', () => require('./App').default);

const css = `
@font-face {
  font-family: "Lato-Light";
  font-style: normal;
  font-weight: 300;
  src: url(${require('./res/fonts/Lato-Light.woff2').default}) format("woff2"),
    url(${require('./res/fonts/Lato-Light.ttf').default}) format("ttf");
}

@font-face {
  font-family: "Lato-Regular";
  font-style: normal;
  font-weight: 400;
  src: url(${
    require('./res/fonts/Lato-Regular.woff2').default
  }) format("woff2"),
   url(${require('./res/fonts/Lato-Regular.ttf').default}) format("ttf");
}

@font-face {
  font-family: "Lato-Bold";
  font-style: normal;
  font-weight: 700;
  src: url(${require('./res/fonts/Lato-Bold.woff2').default}) format("woff2"),
   url(${require('./res/fonts/Lato-Bold.ttf').default}) format("ttf");
}

@font-face {
  font-family: "MaterialIcons";
  src: url(${
    require('./res/fonts/MaterialIcons.woff2').default
  }) format("woff2"),
   url(${
     require('react-native-vector-icons/Fonts/MaterialIcons.ttf').default
   }) format("ttf");
}

.container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
}
`;
const style = document.createElement('style');
style.innerHTML = css;
document.body.appendChild(style);

const rootTag = document.createElement('div');
rootTag.className = 'container';
document.body.append(rootTag);

AppRegistry.runApplication('palmoil', {
  initialProps: {},
  rootTag,
});
