import React from 'react';
import {
  ActivityIndicator,
  Image,
  StatusBar,
  StyleSheet,
  Text,
  View,
  Platform,
  useWindowDimensions,
} from 'react-native';
import Touchable from 'react-native-platform-touchable';
//import Animated from 'react-native-reanimated';
import Icon from 'react-native-vector-icons/MaterialIcons';
import {SafeAreaView} from 'react-native-safe-area-context';
import {styled} from 'linaria/react';

//import {useFade} from '../lib/animations';
import Menu from '../screens/Menu';
import {Link} from './Link';
import {ScrollView} from './ScrollView';
import {useNavigationState} from '@react-navigation/native';

const childArray = (value) => {
  if (!Array.isArray(value)) {
    return [value];
  } else {
    return value;
  }
};

export const BreakingText = (props) => (
  <Text testID={props.testID}>
    {childArray(props.children).map((child) =>
      child && child.split
        ? child.split(' ').map((word, i) => (
            <Text
              key={i}
              style={props.style}
              maxFontSizeMultiplier={props.maxFontSizeMultiplier}>
              {word + ' '}
            </Text>
          ))
        : child,
    )}
  </Text>
);

let BgImage = null;

if (Platform.OS === 'web') {
  BgImage = styled.img`
    position: absolute;
    top: -10%;
    left: 0;
    height: 130%;
  `;
}

export const Background = (props) => (
  <>
    <StatusBar
      barStyle={Platform.OS === 'ios' ? 'dark-content' : 'light-content'}
      maintainVisibleContentPosition={true}
    />
    <View style={styles.bg}>
      {Platform.OS === 'web' ? (
        <BgImage src={require('../res/AppBg.png').uri} />
      ) : (
        <Image style={styles.bgImg} source={require('../res/AppBg.png')} />
      )}
      {props.scroll !== false ? (
        Platform.OS === 'web' ? (
          <ScrollView
            contentContainerStyle={styles.simpleFlex}
            ref={props.scrollRef}>
            {props.children}
          </ScrollView>
        ) : (
          <ScrollView ref={props.scrollRef}>
            <SafeAreaView edges={['left', 'right', 'bottom']}>
              {props.children}
            </SafeAreaView>
          </ScrollView>
        )
      ) : (
        <View style={styles.noScrollContainer}>
          <SafeAreaView edges={['left', 'right', 'bottom']}>
            {props.children}
          </SafeAreaView>
        </View>
      )}
      <LoadingIndicator active={props.loading} />
    </View>
  </>
);

export const Header = (props) => {
  return (
    <>
      <Text style={[styles.title, styles.title1]}>REPLACE</Text>
      <Text style={[styles.title, styles.title2]}>PalmOil</Text>
      <View style={styles.headerSep} />
    </>
  );
};

export const Footer = () => (
  <>
    <View style={styles.footerSep} />
    <View style={styles.footer}>
      <Text style={styles.footerText}>Gefördert durch:</Text>
      <Link href="https://www.postcode-lotterie.de/">
        <Image style={styles.dplLogo} source={require('../res/dpl_logo.png')} />
      </Link>
      <View style={styles.headerSep} />
      <Text style={styles.footerText}>Design:</Text>
      <Link href="https://neuwaerts.de/">
        <Image style={styles.nwLogo} source={require('../res/neuwaerts.png')} />
      </Link>
      <View style={styles.headerSep} />
      <Text style={styles.footerText}>Umsetzung:</Text>
      <Link href="https://uthlande-it.de/">
        <Image style={styles.uthLogo} source={require('../res/uth_logo.png')} />
      </Link>
      <View style={styles.overscrollHangover} />
    </View>
  </>
);

export const HeaderBar = ({navigation, route}) => {
  const isWide = false; // useWindowDimensions().width > 900;
  const isTop = useNavigationState((state) => state.index === 0);

  return (
    <SafeAreaView edges={['top']}>
      {isWide ? (
        <Menu horizontal={true} navigation={navigation} />
      ) : (
        <View style={styles.logoContainer}>
          {!isTop ? (
            <Touchable
              onPress={() => navigation.goBack()}
              background={Touchable.Ripple('#a62731', true)}>
              <Icon name="chevron-left" size={40} color="#a62731" />
            </Touchable>
          ) : (
            <Icon name="chevron-left" size={40} color="#fff" />
          )}
          <Touchable onPress={() => navigation.popToTop()}>
            <Image style={styles.logo} source={require('../res/icon.png')} />
          </Touchable>
          <Touchable
            onPress={() => {
              if (route.name === 'Menu') {
                navigation.goBack();
              } else {
                navigation.push('Menu');
              }
            }}
            background={Touchable.Ripple('#a62731', true)}>
            <Icon name="menu" size={30} color="#a62731" />
          </Touchable>
        </View>
      )}
    </SafeAreaView>
  );
};

export const LoadingIndicator = (props) => {
  /*const [opacity, visible] = useFade(props.active);
  return visible ? (
    <Animated.View style={[styles.loadingContainer, {opacity}]}>
      <ActivityIndicator style={styles.loadingIndicator} size="large" />
    </Animated.View>
  ) : null;*/

  return props.active ? <ActivityIndicator style={styles.loadingIndicator} size="large" /> : null;
};

export const TextBg = (props) => {
  const isWide = useWindowDimensions().width > 1470;

  return (
    <View
      style={[styles.textBg, isWide ? null : styles.narrowTextBg, props.style]}>
      {props.children}
    </View>
  );
};

export const Separator = (props) => (
  <View style={[styles.headerSep, props.style]} />
);

export const WidthLimit = (props) => (
  <View style={{...styles.bodyWidth, width: props.fixed ? '100%' : undefined }}>{props.children}</View>
);

export const styles = StyleSheet.create({
  simpleFlex: {
    flex: 1,
  },
  textContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  logoContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: 10,
    paddingBottom: 10,
    paddingRight: 10,
    borderBottomWidth: 1,
    borderBottomColor: '#a62731',
    backgroundColor: '#fff',
  },
  logo: {
    alignSelf: 'center',
    width: 50,
    height: 50,
    borderRadius: 25,
  },
  bg: {
    backgroundColor: '#F4E4AD',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  bgImg: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: 1080 * 0.35,
    height: 1920 * 0.35,
  },
  noScrollContainer: {
    flex: 1,
    justifyContent: 'flex-start',
  },
  title: {
    textAlign: 'center',
    fontSize: Platform.OS === 'web' ? 24 : 19,
    fontFamily: 'Lato-Regular',
  },
  title1: {
    paddingTop: 30,
    fontFamily: 'Lato-Light',
    fontWeight: '300',
    letterSpacing: 1,
    color: '#000',
  },
  title2: {
    fontWeight: '700',
    color: '#a62731',
    letterSpacing: 3,
  },
  headerSep: {
    width: 80,
    height: 2,
    backgroundColor: '#a62731',
    alignSelf: 'center',
    margin: 10,
  },
  footerSep: {
    marginTop: 30,
    height: 1,
    backgroundColor: '#a62731',
  },
  footer: {
    backgroundColor: 'rgba(255, 255, 255, 0.7)',
    paddingTop: 10,
    paddingBottom: 10,
    alignItems: 'center',
    flexGrow: 1,
    flexShrink: 0,
  },
  footerText: {
    fontFamily: 'Lato-Regular',
    fontSize: 18,
    color: 'rgb(48, 84, 125)',
    ...Platform.select({
      web: {
        fontSize: 20,
        paddingBottom: 5,
      },
    }),
  },
  dplLogo: {
    width: 490 * 0.45,
    height: 326 * 0.45,
    marginTop: 10,
    marginBottom: 10,
  },
  nwLogo: {
    width: 500 * 0.25,
    height: 486 * 0.25,
  },
  uthLogo: {
    width: 1340 * 0.2,
    height: 268 * 0.2,
  },
  loadingContainer: {
    position: Platform.OS === 'web' ? 'fixed' : 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(255, 255, 255, 0.6)',

    alignItems: 'center',
    justifyContent: 'center',
  },
  overscrollHangover: {
    position: 'absolute',
    bottom: -300,
    left: 0,
    right: 0,
    height: Platform.OS === 'web' ? 0 : 300,
    backgroundColor: 'rgba(255, 255, 255, 0.7)',
  },
  bodyWidth: {
    maxWidth: 800,
    alignSelf: 'center',
  },
  textBg: {
    padding: 5,
    borderRadius: 10,
  },
  narrowTextBg: {
    backgroundColor: 'rgba(255, 255, 255, 0.5)',
  },
});

export const textStyle = {
  fontFamily: 'Lato-Regular',
  fontSize: Platform.OS === 'web' ? 19 : 17,
};

export const boldStyle = {
  ...textStyle,
  fontFamily: 'Lato-Bold',
};
