import React from 'react';
import {Platform, View, Text, StyleSheet, TouchableOpacity} from 'react-native';

import {textStyle, boldStyle} from './Layout';

import InfoIcon from '../res/info.svg';

const HelpButton = (props) => (
  <View style={styles.helpContainer}>
    <TouchableOpacity style={styles.infoTouchable} onPress={props.onHelpPress}>
      <InfoIcon
        style={{
          alignSelf: 'center',
        }}
        width="80%"
        height="80%"
        fill="#000"
      />
    </TouchableOpacity>
  </View>
);

export default (props) => {
  const words = props.children.split(' ');
  const lastWord = words.length > 0 ? words.pop() : '';
  return (
    <View style={[styles.subtitleContainer, props.style]} testID={props.testID}>
      {words.map((word, i) => (
        <Text
          key={i}
          style={[
            styles.subtitle,
            props.red ? styles.redSubtitle : styles.greenSubtitle,
            props.thin ? styles.thin : null,
          ]}
          numberOfLines={1}>
          {word}
        </Text>
      ))}
      <Text
        style={[
          styles.subtitle,
          props.red ? styles.redSubtitle : styles.greenSubtitle,
          props.thin ? styles.thin : null,
          styles.lastSubWord,
        ]}
        numberOfLines={1}>
        {lastWord}
        {props.onHelpPress && Platform.OS === 'web' ? HelpButton(props) : null}
      </Text>
      {props.onHelpPress && Platform.OS !== 'web' ? HelpButton(props) : null}
    </View>
  );
};

const styles = StyleSheet.create({
  subtitleContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  subtitle: {
    ...boldStyle,
    fontSize: boldStyle.fontSize * 1.24,
    textAlign: 'center',
    padding: 3,
    letterSpacing: Platform.OS === 'web' ? 1 : 0,
  },
  redSubtitle: {
    color: '#a62731',
  },
  greenSubtitle: {
    color: '#2a4220',
  },
  thin: {
    ...textStyle,
    fontSize: textStyle.fontSize * 1.24,
    letterSpacing: 0,
  },
  lastSubWord: {
    position: 'relative',
    overflow: 'visible',
  },
  helpContainer: {
    position: 'relative',
    ...Platform.select({
      web: {
        top: -10,
        left: 0,
      },
      default: {
        top: 0,
        left: 0,
      },
    }),
    backgroundColor: '#fff',
    borderRadius: 25,
    width: 25,
    height: 25,
    justifyContent: 'center',
    alignContent: 'center',
  },
  helpText: {
    ...textStyle,
    fontSize: textStyle.fontSize * 0.82,
    textAlign: 'center',
    color: '#000',
  },
  infoTouchable: {
    flex: 1,
    alignContent: 'center',
    justifyContent: 'center',
  },
});
