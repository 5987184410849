import React from 'react';
import {
  Keyboard,
  Platform,
  StyleSheet,
  Text,
  View,
  useWindowDimensions,
} from 'react-native';
import Touchable from 'react-native-platform-touchable';
import {styled} from 'linaria/react';
import {Footer} from '../components/Layout';
import {ScrollView} from '../components/ScrollView';
import OrangUtan from '../res/orang_utan.svg';

let Item = (props) => (
  <Touchable
    foreground={Touchable.Ripple('#933133')}
    activeOpacity={0.75}
    onPress={() => props.ctx.switchTo(props.target)}>
    <View
      style={[
        styles.item,
        props.ctx.horizontal ? styles.horizontalItem : null,
      ]}>
      <Text style={styles.itemText}>{props.children}</Text>
    </View>
  </Touchable>
);

if (Platform.OS === 'web') {
  const ItemLink = styled.a`
    font-family: 'Lato-Regular', sans-serif;
    font-size: 19px;
    text-decoration: none;
    text-align: center;
    color: #933133;

    background-color: ${(props) => (props.horizontal ? '#fff' : '#F4E4AD')};
    padding: 20px;
    border: 0px solid #933133;
    border-bottom-width: 1px;

    position: relative;

    &:hover {
      background-color: ${(props) => (props.horizontal ? '#F4E4AD' : '#fff')};
    }
  `;

  Item = (props) => (
    <ItemLink
      href="#"
      horizontal={props.ctx.horizontal}
      onClick={() => props.ctx.switchTo(props.target)}>
      {props.children}
    </ItemLink>
  );
}

export default ({horizontal, navigation}) => {
  const windowDim = useWindowDimensions();

  function switchTo(component) {
    Keyboard.dismiss();
    navigation.replace(component);
  }

  const orangMaxWidth = Math.min(windowDim.width - 20, 700);
  const orangDim = {
    height: (663 / 1427) * orangMaxWidth,
    width: Platform.OS === 'web' ? '100%' : orangMaxWidth,
  };

  const ctx = {switchTo, horizontal};

  return (
    <ScrollView
      style={[styles.container, horizontal ? styles.horizontal : null]}>
      {horizontal ? (
        <Item target="Home" ctx={ctx}>
          Startseite
        </Item>
      ) : null}
      <Item target="ScanHistory" ctx={ctx}>
        Gescannte Produkte
      </Item>
      <Item target="AboutUs" ctx={ctx}>
        Über Replace PalmOil
      </Item>
      <Item target="ThankYou" ctx={ctx}>
        Dankeschön
      </Item>
      <Item target="Privacy" ctx={ctx}>
        Datenschutzerklärung
      </Item>
      <Item target="Impressum" ctx={ctx}>
        Impressum
      </Item>
      {horizontal ? (
        <View style={styles.horizontalEnd} />
      ) : (
        <View style={styles.orangUtan}>
          <OrangUtan width={orangDim.width} height={orangDim.height} />
        </View>
      )}
      <Footer />
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#F4E4AD',
    height: '100%',
  },
  horizontal: {
    flexDirection: 'row',
    alignContent: 'flex-start',
  },
  item: {
    backgroundColor: '#F4E4AD',
    padding: 20,
    borderBottomWidth: 1,
    borderBottomColor: '#933133',
  },
  horizontalItem: {
    backgroundColor: '#FFF',
  },
  horizontalEnd: {
    flex: 1,
    backgroundColor: '#FFF',
    borderBottomWidth: 1,
    borderBottomColor: '#933133',
  },
  itemText: {
    color: '#933133',
    textAlign: 'center',
  },
  orangUtan: {
    margin: 10,
    marginBottom: -20,
    ...Platform.select({
      web: {
        justifySelf: 'center',
      },
    }),
  },
});
